# Location_type
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->

  select_location_type  = $('.select_location_type')
  select_estate_group   = $('.form-group.select_estate')
  select_estate         = $('.select_location_estate')
  select_building_group = $('.form-group.select_building')
  select_building       = $('.select_location_building')
  select_floor_group    = $('.form-group.select_floor')
  select_floor          = $('.select_location_floor')
  select_room_group     = $('.form-group.select_room')
  select_room           = $('.select_location_room')
  input_room_name_group = $('.form-group.input_room_name')
  input_room_name       = $('.select_location_room_name')
  select_vehicle_group  = $('.form-group.select_vehicle')
  select_vehicle        = $('.select_location_vehicle')

  # The select tag last in a certain cascade, for example when we set 'Floor' as location_type, needs a certain name the be valid and get recoginized within the params hash. As this script is used for in different places, QrCode form, Monitoring sheet form, etc., we extract it from a hidden field within select_location.html
  active_select_location_id_name = $('#active_select_location_id_name').attr('name')

  select_location_type.change ->
    # First disable everything and show only select_estate_group
    select_estate_group.removeClass('d-none')
    select_estate.attr('name', 'disabled')
    select_building_group.addClass('d-none')
    select_building.attr('name', 'disabled')
    select_floor_group.addClass('d-none')
    select_floor.attr('name', 'disabled')
    select_room_group.addClass('d-none')
    select_room.attr('name', 'disabled')
    input_room_name_group.addClass('d-none')
    input_room_name.attr('disabled', true)
    select_vehicle_group.addClass('d-none')
    select_vehicle.attr('name', 'disabled')
    # … then enable conditionally
    switch select_location_type.val()
      when 'Estate'
        select_estate.attr('name', active_select_location_id_name)
      when 'Building'
        select_building_group.removeClass('d-none')
        select_building.attr('name', active_select_location_id_name)
      when 'Floor'
        select_building_group.removeClass('d-none')
        select_floor_group.removeClass('d-none')
        select_floor.attr('name', active_select_location_id_name)
        input_room_name_group.removeClass('d-none')
        input_room_name.attr('disabled', false)
      when 'Room'
        select_building_group.removeClass('d-none')
        select_floor_group.removeClass('d-none')
        select_room_group.removeClass('d-none')
        select_room.attr('name', active_select_location_id_name)
      when 'Vehicle'
        select_estate_group.addClass('d-none')
        select_vehicle_group.removeClass('d-none')
        select_vehicle.attr('name', active_select_location_id_name)

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_location_type.trigger 'change'


  # Location id
  # -------------------------------------------------------------------

  buildings       = select_building.html()
  floors          = select_floor.html()
  rooms           = select_room.html()

  empty_option = "<option value='' selected='selected'></option>"

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_estate.val() == ''
  select_floor.prop('disabled', true) if select_building.val() == ''
  select_room.prop('disabled', true) if select_floor.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()

    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      select_floor.prop('disabled', true) if select_building.val() == ''
      select_room.prop('disabled', true) if select_floor.val() == ''
      # Auto-select if there is only one building
      select_building[0].selectedIndex = 1 if select_building.children('option').length == 2
    else
      select_building.prop('disabled', true)
    select_building.prepend(empty_option) if select_building.find('option:first-child').val() != ''
    select_building[0].selectedIndex = 0 unless selected_building > 0 || select_building.children('option').length == 2
    populate_select_floors()
    select_building.change()

  # Populate floors
  selected_floor = select_floor.find('option:selected').val()
  select_building.change ->
    selected_building = select_building.find('option:selected').val()
    populate_select_floors()

  populate_select_floors = ->
    options = $(floors).filter("optgroup[label='#{selected_building}']").html()
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
      select_room.prop('disabled', true) if select_floor.val() == ''
      # Auto-select if there is only one floor
      select_floor[0].selectedIndex = 1 if select_floor.children('option').length == 2
    else
      select_floor.prop('disabled', true)
    select_floor.prepend(empty_option) if select_floor.find('option:first-child').val() != ''
    select_floor[0].selectedIndex = 0 unless selected_floor > 0 || select_floor.children('option').length == 2
    populate_select_rooms()
    setTimeout((-> select_floor.change()), 100)

  # Populate rooms
  selected_room = select_room.find('option:selected').val()
  select_floor.change ->
    selected_floor = select_floor.find('option:selected').val()
    populate_select_rooms()

  populate_select_rooms = ->
    options = $(rooms).filter("optgroup[label='#{selected_floor}']").html()
    if options
      select_room.prop('disabled', false)
      select_room.html(options)
      # Add the empty option in the else branch when the select is disabled
    else
      select_room.prop('disabled', true)
      select_room.html(empty_option)  # Add this line
    select_room.prepend(empty_option) if select_room.find('option:first-child').val() != ''
    select_room[0].selectedIndex = 0 unless selected_room > 0
    setTimeout( ->
      if select_room.children('option').length == 2
        select_room[0].selectedIndex = 1
        select_room.trigger('change')
    , 100)

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  # select_estate.trigger 'change'
