# Add closed/open class to .measuring-point on evaluate
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  $('#monitoring_sheet_entries').on 'show.bs.collapse', (event) ->
    event.target.closest('.monitoring_sheet').classList.add('open')
    event.target.closest('.monitoring_sheet').classList.remove('closed')
  $('#monitoring_sheet_entries').on 'hide.bs.collapse', (event) ->
    event.target.closest('.monitoring_sheet').classList.remove('open')
    event.target.closest('.monitoring_sheet').classList.add('closed')


# Click the 'next' key by pressing enter
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  if $('#monitoring_sheet_entries').length > 0
    $(document).on 'keydown', (event) ->
      # Check if the Enter key (keyCode 13) is pressed without other modifier keys
      if event.keyCode == 13 && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey
        # Prevent the default Enter behavior if needed
        event.preventDefault()

        # Find the first visible `a.next` inside the accordion
        next_link = $('#monitoring_sheet_entries a.next:visible').first()

        # If a visible `a.next` exists, trigger a click
        if next_link.length > 0
          next_link.click()


# Focus on the first input, textarea, or select within a .form-group
# inside the visible collapse
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  if $('#monitoring_sheet_entries').length > 0
    # Listen for the 'shown.bs.collapse' event on all collapsible elements within the accordion
    $('#monitoring_sheet_entries .collapse').on 'shown.bs.collapse', (event) ->
      # The collapse element that has just been shown
      visible_collapse = $(event.target)

      # Find the first input, textarea, or select within a .form-group inside the visible collapse
      first_input = visible_collapse.find('.form-group').find('input, textarea, select').first()

      # Focus on the found element if it exists
      first_input.focus() if first_input.length > 0


# Show recurring details in (disabled) text input on start
# ---------------------------------------------------------------------

$(document).on 'turbolinks:load', ->
  select_ods = $('#monitoring_sheet_template_id')
  ods_recurring_details = $('.monitoring_template_recurring_details')

  select_ods.change ->
    selected_sheet_id = select_ods.find('option:selected').val()
    ods_recurring_single = ods_recurring_details.find("#monitoring_template_recurring_details_#{selected_sheet_id}")
    ods_recurring_details.find('div').css('display', 'none')
    if ods_recurring_single.length > 0
      ods_recurring_single.css('display', 'block')

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_ods.trigger 'change'

  # Enable clockpicker for value_time
  $('.clockpicker-monitoring-sheet-entry').clockpicker
    autoclose: 'true'
    placement: 'bottom'
    default: 'now'
    align: 'right'
    donetext: '✔'
  return
