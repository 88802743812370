/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other
// files present in this directory. You're encouraged to place your
// actual application logic in a relevant structure within
// app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %>
// to the appropriate layout file, like
// app/views/layouts/application.html.erb

import 'bootstrap'
import 'clockpicker/dist/bootstrap-clockpicker'
import 'bootstrap-datepicker'
import 'bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min'
import '../vendor/javascripts/jasny-bootstrap'
import 'jquery-ujs'
import 'jquery-ui/ui/widgets/sortable'
import '../vendor/javascripts/modernizr_cssfilters'
import '@nathanvda/cocoon'
import '../vendor/javascripts/recurring-select.js.erb'

import '../javascripts/turbolinks-settings' // needs to be first
import '../javascripts/direct_uploads'
import '../javascripts/lightbox'
import '../javascripts/pagination.js.erb'
import '../javascripts/select2'
import '../javascripts/select_location'
import '../javascripts/slider'
import '../javascripts/static_document'
// import '../javascripts/select_estate_building_floor'
import '../javascripts/select_sgc_sg'

import '../javascripts/common'

import '../javascripts/controllers/check_ins'
import '../javascripts/controllers/contact_persons'
import '../javascripts/controllers/floors'
import '../javascripts/controllers/inspections'
import '../javascripts/controllers/inspection_settings'
import '../javascripts/controllers/notices'
import '../javascripts/controllers/monitoring_templates'
import '../javascripts/controllers/monitoring_sheets'
import '../javascripts/controllers/qr_codes'
import '../javascripts/controllers/reports'
import '../javascripts/controllers/reports_schedules'
import '../javascripts/controllers/roles'
import '../javascripts/controllers/rooms'
import '../javascripts/controllers/services'
import '../javascripts/controllers/tickets'
import '../javascripts/controllers/ticket_workflow'
import '../javascripts/controllers/time_trackings.js.erb'

global.jQuery = $
global.$ = $
