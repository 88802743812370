$(document).on 'turbolinks:load', ->

  # Set dropdowns and weight input for first page of new inspections
  # ---------------------------------------------------------------------

  select_estate   = $('#room_estate')
  select_building = $('#room_building')
  select_floor    = $('#room_floor_id')
  buildings       = select_building.html()
  floors          = select_floor.html()

  empty_option = "<option value='' selected='selected'></option>"

  # Disable secondary/tertiary selects of cascades
  select_building.prop('disabled', true) if select_estate.val() == ''
  select_floor.prop('disabled', true) if select_building.val() == ''

  # Populate buildings
  selected_building = select_building.find('option:selected').val()
  select_estate.change ->
    selected_estate = select_estate.find('option:selected').val()
    options = $(buildings).filter("optgroup[label='#{selected_estate}']").html()
    number_of_buildings = if options then (options.match(/value/g) || []).length else 0
    if options
      select_building.prop('disabled', false)
      select_building.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Building is selectable
      unless selected_building > 0 || number_of_buildings == 1
        select_building.prepend(empty_option)
      select_floor.prop('disabled', true) if select_building.val() == ''
    else
      select_building.empty()
    populate_select_floors()

  # Populate floors
  select_building.change ->
    select_floor.prop('disabled', false) if select_building.val() != ''
    populate_select_floors()

  populate_select_floors = ->
    building = select_building.find(':selected').val()
    options = $(floors).filter("optgroup[label='#{building}']").html()
    floor = select_floor.find('option:selected').val()
    number_of_floors = if options then (options.match(/value/g) || []).length else 0
    if options
      select_floor.prop('disabled', false)
      select_floor.html(options)
      # If nothing is selected yet, add empty option, so User has to select right value first, but only when more than one Floor is selectable
      unless floor > 0 || number_of_floors == 1
        select_floor.prepend(empty_option)
    else
      select_floor.empty()

  # IMPORTANT
  # Also trigger everything after page (re)load, e.g. validation fail
  select_estate.trigger 'change'
