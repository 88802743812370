# # Show/hide start_at, recipient
$(document).on 'turbolinks:load', ->
  template_recurring = $('#monitoring_template_recurring')
  template_start_time_recurring = $('.start_time_membership')

  template_recurring.change ->
    if template_recurring.val() != 'null'
      template_start_time_recurring.show()
      template_start_time_recurring.find('input').prop( 'disabled', false )
    else
      template_start_time_recurring.hide()
      template_start_time_recurring.find('input').prop( 'disabled', true )


# Show/hide options
$(document).on 'change', '.select-template-field-kind', (event) ->
  event.preventDefault()
  if $(this).val() == 'selection'
    $(this).closest('fieldset').find('.field_options').show()
  else
    $(this).closest('fieldset').find('.field_options').hide()


# Change order/position of header info and measuring points
# ---------------------------------------------------------

# DRY version of hideAndShowPositionLinks function
hideAndShowPositionLinks = (fields) ->
  visibleFields = $(fields).filter(':visible')
  countOfFields = visibleFields.length

  $(visibleFields).each (index) ->
    $(this).find('.position').val(index + 1)

    # Hide position links if there is only one field
    if countOfFields == 1
      $(this).find('.position_up').css('visibility', 'hidden')
      $(this).find('.position_down').css('visibility', 'hidden')
      $(this).find('.positon_handle').css('visibility', 'hidden')
      return

    # First element
    if index == 0
      $(this).find('.position_up').css('visibility', 'hidden')
      $(this).find('.position_down').css('visibility', 'visible')
      $(this).find('.positon_handle').css('visibility', 'visible')
    # Last element (unless there are only two elements)
    else if index == countOfFields - 1
      $(this).find('.position_down').css('visibility', 'hidden')
      $(this).find('.position_up').css('visibility', 'visible')
    # All other elements
    else
      $(this).find('.position_down').css('visibility', 'visible')
      $(this).find('.position_up').css('visibility', 'visible')

# DRY version of moveItem function
moveItem = (fields, direction) ->
  $(document).on 'click', "#{fields} .position_#{direction}", (event) ->
    event.preventDefault()

    fieldItem = $(this).closest(fields)
    fieldItemSibling = fieldItem[if direction == 'up' then 'prev' else 'next'](fields)
    fieldItem[if direction == 'up' then 'insertBefore' else 'insertAfter'](fieldItemSibling)

    hideAndShowPositionLinks(fields)

$(document).on 'cocoon:after-insert', (event, insertedItem, originalEvent) ->
  hideAndShowPositionLinks('.header_info_fields')
  hideAndShowPositionLinks('.monitoring_template_fields')

$(document).on 'cocoon:after-remove', (event, insertedItem, originalEvent) ->
  hideAndShowPositionLinks('.header_info_fields')
  hideAndShowPositionLinks('.monitoring_template_fields')

$(document).on 'turbolinks:load', ->
  hideAndShowPositionLinks('.header_info_fields')
  hideAndShowPositionLinks('.monitoring_template_fields')

# Use general functions for .header_info_fields and .monitoring_template_fields
['.header_info_fields', '.monitoring_template_fields'].forEach (field) ->
  hideAndShowPositionLinks(field)
  moveItem(field, 'up')
  moveItem(field, 'down')


$(document).on 'turbolinks:load', ->
  $('#monitoring_template_form .sortable').sortable
    cursor: 'move',
    handle: '.positon_handle',
    update: (event, ui) ->
      hideAndShowPositionLinks('.header_info_fields')
      hideAndShowPositionLinks('.monitoring_template_fields')
