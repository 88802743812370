$ ->

  select_data_acquisition_type   = $('select#reports_schedule_report_type')
  select_reports_kind   = $('select#reports_schedule_reports_kind')

  inspection_administered_by_form_group = $('#reports_schedule_administered_by').parent()
  service_group_category_form_group = $('#reports_schedule_service_group_category_id').parent()

  if $('body.reports_schedules-new').length > 0
    # Show option for daily_defect_inspections only when Inspection reports are selected
    select_reports_kind_options = select_reports_kind.children()

    select_data_acquisition_type.change ->
      # Show ServiceGroupCategory select only when Inspection reports are selected
      if select_data_acquisition_type.val() == 'inspections' || select_data_acquisition_type.val() == 'defect_inspections'
        inspection_administered_by_form_group.show()
        service_group_category_form_group.show()
      else
        inspection_administered_by_form_group.hide()
        service_group_category_form_group.hide()
